import { createReducer, on } from '@ngrx/store';

import { addTour, removeTour } from '../actions/tour.action';
import { initialState } from '../state/tour.state';

export const tourReducer = createReducer(
  initialState,
  on(addTour, (state, { tour, tourUrl }) => {
    // we can select only 1 tour at a time
    return { ...state, tours: [tour, tourUrl] };
  }),
  on(removeTour, (state, { tour }) => {
    return {
      ...state,
      tours: state.tours.filter((tourUid: string) => tour !== tourUid),
    };
  }),
);
