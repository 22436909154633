<div class="pseudo-guard">
  <form (ngSubmit)="onSubmit(password)">
    <div class="mb-4">
      <h5 class="form-header-text">
        {{ 'SHOWROOM.AUTHENTICATE.PSEUDO_PASS_HEADER' | translate }}
      </h5>
    </div>
    <div class="form-group">
      <label for="pseudoPassword" class="mb-3 form-label-text">{{
        'SHOWROOM.AUTHENTICATE.PSEUDO_PASS' | translate
      }}</label>
      <input
        type="password"
        class="form-control mb-4 rounded-0"
        [ngClass]="isInvalid ? 'is-invalid' : ''"
        id="pseudoPassword"
        for="pseudoPassword"
        [(ngModel)]="password"
        name="pseudoPassword"
      />
    </div>
    <div class="form-error-message text-danger" *ngIf="isInvalid">
      <i class="bi bi-x-circle"></i>
      {{ 'SHOWROOM.AUTHENTICATE.PASS_ERROR_MSG' | translate }}
    </div>
    <button type="submit" class="btn btn-danger rounded-0 form-button">
      {{ 'SHOWROOM.AUTHENTICATE.ENTER' | translate }}
    </button>
  </form>
</div>
