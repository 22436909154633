import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TokenService } from '../../services/token.service';
import { ApiService } from '../../services/api.service';
import { TouristResponseData } from '../../models/response/tourist.response.model';

@Component({
  selector: 'tbyb-post-login',
  templateUrl: './post-login.component.html',
})
export class PostLoginComponent implements OnInit {
  constructor(
    private tokenService: TokenService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    const disciplineUidFromMagicLink =
      this.route.snapshot.queryParamMap.get('discipline');
    if (disciplineUidFromMagicLink) {
      sessionStorage.setItem(
        'selectedDisciplineUid',
        disciplineUidFromMagicLink,
      );
    }
    const goalUidsFromMagicLink =
      this.route.snapshot.queryParamMap.get('goals');
    if (goalUidsFromMagicLink) {
      sessionStorage.setItem('selectedGoalsUids', goalUidsFromMagicLink);
    }
    const disciplineUid = sessionStorage.getItem('selectedDisciplineUid');
    const goalUid = sessionStorage.getItem('selectedGoalsUids');
    const personXid = this.tokenService.getPersonXid();
    if (disciplineUid && goalUid && personXid) {
      this.postLoginInit(personXid, disciplineUid, goalUid);
    } else if (personXid) {
      this.isResumeConnectPreview(personXid);
    } else {
      this.router.navigate(['disciplines']);
    }
  }

  postLoginInit(personXid: string, discipline: string, goals: string): void {
    this.apiService.addTourist(personXid, discipline, goals).subscribe({
      next: (res: TouristResponseData) => {
        if (res.metadata.code === 200) {
          this.router.navigate(['tours']);
        }
      },
      error: (err) => {
        console.error(
          `Post Login with discipline and goal encountered a problem - ${err.message}`,
        );
        this.router.navigate(['disciplines']);
      },
    });
  }

  isResumeConnectPreview(personXid: string) {
    this.apiService.getTouristInfo(personXid).subscribe({
      next: (res: TouristResponseData) => {
        if (res?.data?.touristDiscipline && res?.data?.touristGoal) {
          this.router.navigate(['tours']);
        }
      },
      error: (err) => {
        console.error(
          `Post Login with personXid encountered a problem - ${err.message}`,
        );
        this.router.navigate(['disciplines']);
      },
    });
  }
}
