import { Injectable } from '@angular/core';
import { UserMetaData } from '../models/pendo.model';

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  private intervalCounter = 0;
  private loadInterval;

  initializeAgent(userPendo: UserMetaData) {
    this.intervalCounter++;

    if (!window['pendo'] || !window['pendo']?.validateInstall) {
      if (this.intervalCounter === 30) {
        clearInterval(this.loadInterval);
      }
      return;
    }
    clearInterval(this.loadInterval);

    window['pendo'].initialize({
      visitor: {
        id: userPendo.personXid,
        connect_language: userPendo?.language || navigator?.language,
      },
      account: {
        id: userPendo.orgXid,
      },
    });
  }

  identify(userPendo: UserMetaData) {
    if (!window['pendo'] || !window['pendo']?.validateInstall) {
      this.initialize(userPendo);
    }
    if (window['pendo'] || window['pendo']?.validateInstall) {
      window['pendo'].identify({
        visitor: {
          id: userPendo.personXid,
          showroom_tbyb: userPendo.tourUid,
          connect_language: userPendo?.language || navigator?.language,
        },
        account: {
          id: userPendo.orgXid,
        },
      });
    }
  }

  initialize(userPendo: UserMetaData) {
    /**
     * this is needed as pendo
     * sometimes takes a little bit
     * to load for google tag manager
     */
    this.loadInterval = setInterval(() => {
      this.initializeAgent(userPendo);
    }, 1000);
  }
}
