import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpBackend } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import {
  NgbModule,
  NgbNavModule,
  NgbProgressbarModule,
  NgbAlertModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SafePipeModule } from 'safe-pipe';

// Reducer imports
import { exampleReducer } from './store/reducers/example.reducer';
import { tourReducer } from './store/reducers/tour.reducer';
// Module imports
import { AppRoutingModule } from './app-routing.module';
import { ExampleModule } from './components/example/example.module';
// Component imports
import { AppComponent } from './app.component';
import { ProgressBarComponent } from './shared-bootstrap/progress-bar/progress-bar.component';
import { DropdownComponent } from './shared-bootstrap/dropdown/dropdown.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PasscodeComponent } from './components/passcode/passcode.component';
import { SystemErrorComponent } from './components/system-error/system-error.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DisciplinesComponent } from './components/disciplines/disciplines.component';
import { GoalsComponent } from './components/goals/goals.component';
import { ToursComponent } from './components/tours/tours.component';
import { TourEndComponent } from './components/tour-end/tour-end.component';
import { TourNotReadyComponent } from './components/tour-not-ready/tour-not-ready.component';
import { PostLoginComponent } from './components/post-login/post-login.component';

// see https://github.com/rbalet/ngx-translate-multi-http-loader#readme for more info
export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, ['./assets/i18n/']);
}

@NgModule({
  declarations: [
    AppComponent,
    ProgressBarComponent,
    DropdownComponent,
    HeaderComponent,
    FooterComponent,
    PasscodeComponent,
    SystemErrorComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    DisciplinesComponent,
    GoalsComponent,
    ToursComponent,
    TourEndComponent,
    TourNotReadyComponent,
    PostLoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbAlertModule,
    SafePipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    StoreModule.forRoot({
      items: exampleReducer,
      tours: tourReducer,
    }),
    ExampleModule,
  ],
  providers: [],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
