<div class="d-flex flex-column min-vh-100">
  <div
    class="flex-grow-1 flex-shrink-0 mt-4 container"
    data-automation-id="goals-container"
  >
    <tbyb-header
      (highlightMainContent)="onHighlightMainContent()"
    ></tbyb-header>
    <main
      role="main"
      id="main-content"
      tabindex="-1"
      [ngClass]="{ 'main-container-highlight': showHighlightOnMain }"
    >
      <div class="mt-5 mb-5">
        <h1
          class="mb-3 fs-1 fw-bold"
          data-automation-id="goals-page-title"
          id="goals-page-title"
          role="heading"
          aria-level="1"
        >
          {{ 'SHOWROOM.GOALS.TITLE' | translate }}
        </h1>
        <h2
          class="mb-2 fs-2 fw-normal"
          data-automation-id="goals-page-sub-title"
          id="goals-page-sub-title"
          role="heading"
          aria-level="2"
        >
          {{ 'SHOWROOM.GOALS.SUB_TITLE' | translate }}
        </h2>
        <h3
          class="fs-4 fw-normal"
          data-automation-id="goals-page-description"
          id="goals-page-description"
          role="heading"
          aria-level="3"
        >
          {{ 'SHOWROOM.GOALS.DESCRIPTION' | translate }}
        </h3>
      </div>
      <div class="col-lg-12">
        <ngb-alert
          *ngIf="showAlert"
          class="mt-3"
          data-automation-id="goals-page-fetch-error"
          id="goals-page-fetch-error"
          [type]="alertType"
          [dismissible]="false"
        >
          {{ 'SHOWROOM.GOALS.FETCH_ERROR' | translate }}
        </ngb-alert>
        <div class="row mt-5">
          <ng-container *ngFor="let goalData of goalsData">
            <div
              class="col-xl-4 col-lg-6 col-md-6 p-3 card-container select-goal"
              (click)="selectGoal(goalData.uid)"
              (keypress)="selectGoal(goalData.uid)"
              data-automation-id="goals-card-container"
              id="{{ 'select-goal-' + goalData.id }}"
              tabindex="0"
              role="group"
            >
              <div
                class="card h-100 shadow"
                [ngClass]="{ 'card-selected': goalData?.touristGoalSelected }"
                data-automation-id="goals-card"
              >
                <div
                  class="selected-check-icon"
                  *ngIf="goalData?.touristGoalSelected"
                >
                  <i class="bi bi-check-circle-fill"></i>
                </div>
                <div
                  class="unselected-check-icon"
                  *ngIf="!goalData?.touristGoalSelected"
                >
                  <i class="bi bi-check-circle-fill"></i>
                </div>
                <h4
                  class="fs-4 pt-4 px-3 card-title"
                  data-automation-id="goals-card-title"
                  id="{{ 'goals-card-title-' + goalData.id }}"
                  role="heading"
                  aria-level="4"
                >
                  {{ goalData?.name }}
                </h4>
                <div class="card-body">
                  <p
                    class="fs-6 pt-1 card-text"
                    data-automation-id="goals-card-description"
                    id="{{ 'goals-card-description-' + goalData.id }}"
                  >
                    {{ goalData?.description }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!showAlert" class="my-4 px-1">
        <div class="my-2 px-1" *ngIf="isErrorMessageVisible">
          <i class="bi bi-x-circle error-message-icon"></i>
          <span
            class="error-message"
            data-automation-id="select-goals-error-message"
            id="select-goals-error-message"
            aria-live="assertive"
            role="alert"
          >
            {{ 'SHOWROOM.GOALS.ERROR_MESSAGE' | translate }}</span
          >
        </div>
        <button
          type="button"
          class="tbyb-btn-light fs-5 back-button mt-3"
          (click)="goBack()"
          data-automation-id="goals-page-back-button"
          id="goals-page-back-button"
          aria-label="Go Back to Disciplines page button"
        >
          {{ 'SHOWROOM.GOALS.GO_BACK_BUTTON' | translate }}
        </button>
        <button
          type="button"
          class="tbyb-btn-dark fs-5 continue-button mt-3 mx-3"
          (click)="continue()"
          data-automation-id="goals-page-continue-button"
          id="goals-page-continue-button"
          aria-label="Continue to Tours page button"
          aria-describedby="select-goals-error-message"
        >
          {{ 'SHOWROOM.GOALS.CONTINUE_BUTTON' | translate }}
        </button>
      </div>
    </main>
  </div>
  <tbyb-footer></tbyb-footer>
</div>
