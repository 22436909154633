import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { CONTACT_LINK } from '../../constants/links.constant';

@Component({
  selector: 'tbyb-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  constructor(private router: Router) {}

  contactLink = CONTACT_LINK;
  showHighlightOnMain = false;

  goToConnectPreview() {
    this.router.navigate(['']);
  }

  onHighlightMainContent() {
    this.showHighlightOnMain = true;
  }
}
