import { Injectable } from '@angular/core';

// Service imports
import { ApiService } from '../services/api.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class TokenRefreshService {
  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
  ) {}

  refreshToken() {
    // Condition to verify the user's token before calling refresh token endpoint.
    if (this.tokenService.isUserLoggedIn()) {
      this.apiService.refreshToken().subscribe({
        //We can use this next to set the userLoggedIn boolean as true in global level instead of calling tokenService.isUserLoggedIn() everytime.
        next: () => {},
        //Retry second time if refresh token endpoint errored out on first try.
        error: (err) => {
          this.apiService.retryTimes++;
          if (this.apiService.retryTimes < 2) {
            this.refreshToken();
          } else {
            console.error('apiService refreshToken error:', err);
          }
        },
      });
    }
  }
}
