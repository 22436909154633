import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode, JwtPayload } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private cookieService: CookieService) {}

  getTokenDetails(token: string): JwtPayload {
    return jwtDecode<JwtPayload>(token);
  }

  getCookie(key: string): string {
    return this.cookieService.get(key) || '';
  }

  getPersonXid(): string {
    const mhCred = this.getCookie('MH_TOKEN');
    return mhCred ? this.getTokenDetails(mhCred)['person_xid'] : '';
  }

  isUserLoggedIn(): boolean {
    const mhTokenCookie = this.getCookie('MH_TOKEN');
    const expireTime = mhTokenCookie
      ? this.getTokenDetails(mhTokenCookie)['exp']
      : null;
    const currentTime = new Date().getTime() / 1000;

    return expireTime ? expireTime > currentTime : false;
  }
}
