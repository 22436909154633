<div class="d-flex flex-column min-vh-100">
  <div
    class="flex-grow-1 flex-shrink-0 m-5"
    data-automation-id="system-error-container"
  >
    <tbyb-header></tbyb-header>
    <main
      role="main"
      id="main-content"
      tabindex="-1"
      class="d-md-flex"
      [ngClass]="{ 'main-container-highlight': showHighlightOnMain }"
    >
      <div class="w-75 me-5 mb-5">
        <h1
          class="my-3 fs-1 fw-bold"
          data-automation-id="system-error-title"
          role="heading"
          aria-level="1"
        >
          {{ 'SHOWROOM.SYSTEM_ERROR.TITLE' | translate }}
        </h1>
        <h2
          class="mb-2 fs-4 fw-normal"
          data-automation-id="system-error-sub-title"
          role="heading"
          aria-level="2"
        >
          {{ 'SHOWROOM.SYSTEM_ERROR.SUB_TITLE' | translate }}
        </h2>
        <h3
          class="mb-2 fs-5 fw-normal"
          data-automation-id="system-error-message"
          role="heading"
          aria-level="3"
        >
          {{ 'SHOWROOM.SYSTEM_ERROR.MESSAGE_START' | translate }}
          <a
            class="text-decoration-none"
            tabindex="0"
            target="_blank"
            [href]="contactLink"
            data-automation-id="system-error-contact-link"
            >{{ 'SHOWROOM.SYSTEM_ERROR.MESSAGE_MID' | translate }}</a
          >
          {{ 'SHOWROOM.SYSTEM_ERROR.MESSAGE_END' | translate }}
        </h3>
        <button
          class="tbyb-btn-dark fs-5 mt-3 connect-preview-button"
          id="system-error-connect-preview-button"
          data-automation-id="system-error-connect-preview-button"
          (click)="goToConnectPreview()"
        >
          {{ 'SHOWROOM.SYSTEM_ERROR.CONNECT_PREVIEW_HOME_BUTTON' | translate }}
        </button>
      </div>
      <div class="w-25 me-5">
        <img
          class="d-inline-block align-top magnifying-glass"
          src="assets/images/magnifying-glass.png"
          alt="Magnifying Glass"
          data-automation-id="system-error-magnifying-glass"
        />
      </div>
    </main>
  </div>
  <tbyb-footer></tbyb-footer>
</div>
