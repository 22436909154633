import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'tbyb-passcode',
  templateUrl: './passcode.component.html',
  styleUrl: './passcode.component.scss',
})
export class PasscodeComponent {
  window = window;
  hashLocalKey = 'tour';
  hashLocalValue = Md5.hashStr('tourConnectDemo');
  hashPassword = 'a584efafa8f9ea7fe5cf18442f32b07b';
  password: string;
  isInvalid = false;

  constructor(private route: ActivatedRoute, private router: Router) {}

  onSubmit(password: string) {
    if (Md5.hashStr(password) === this.hashPassword) {
      localStorage.setItem(this.hashLocalKey, this.hashLocalValue);
      this.isInvalid = false;
      this.router.navigate([
        this.route.snapshot.queryParams['returnUrl'] || '',
      ]);
    } else {
      this.password = '';
      this.isInvalid = true;
    }
  }
}
