import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, filter, of, switchMap } from 'rxjs';

import { environment } from '../../../environments/environment';
// Service imports
import { ApiService } from '../../services/api.service';
import { PendoService } from '../../services/pendo.service';
import { TokenService } from '../../services/token.service';
// Enum imports
import { AlertType } from '../../enum/alert.enum';
import { DropdownOption } from '../../enum/dropdown-option';
import { TourType, TourStatus } from '../../enum/tour.enum';
import { TouristTourStatus } from '../../enum/tourist.enum';
// Model imports
import { TourData } from '../../models/tour.model';
import { ToursResponseData } from '../../models/response/tour.response.model';
import {
  TouristDisciplineResponseData,
  TouristResponseData,
  AddTourToTouristResponseData,
} from '../../models/response/tourist.response.model';

@Component({
  selector: 'tbyb-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss'],
})
export class ToursComponent implements OnInit {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private apiService: ApiService,
    private pendoService: PendoService,
    private tokenService: TokenService,
  ) {}

  window = window;
  showHighlightOnMain = false;
  personXid = '';
  selectedDisciplineId: number;
  selectedDisciplineUid = '';
  selectedDisciplineName = '';
  selectedCourseId: number;
  selectedSectionId: number;
  selectedTourUids: string[] = [];
  inProgressToursData: TourData[] = [];
  recommendedToursData: TourData[] = [];
  otherToursData: TourData[] = [];
  completedToursData: TourData[] = [];
  toursData: TourData[] = [];
  isToursSelected = false;
  status = TouristTourStatus.IN_PROGRESS;
  touristResponseData: TouristResponseData;
  pageName = 'tours';
  tourType = TourType;
  // alert
  showAlert = false;
  alertType: AlertType;
  // dropdown
  disciplineDropdownOptions: DropdownOption[] = [];
  selectedDisciplineDropdownOption: DropdownOption = {
    viewValue: '',
    value: '',
  };
  dropdownId = '';

  ngOnInit(): void {
    this.getPersonXid()
      .pipe(
        filter((personXid: string) => {
          if (!personXid) {
            this.router.navigate(['disciplines']);
            return false;
          }
          return true;
        }),
        switchMap((personXid: string) => {
          this.personXid = personXid;
          return this.getTouristData();
        }),
        switchMap((touristResponseData: TouristResponseData) => {
          this.touristResponseData = touristResponseData;
          this.setSelectedDisciplineData();
          return of(this.selectedDisciplineUid);
        }),
        filter((selectedDisciplineUid: string) => {
          if (!selectedDisciplineUid) {
            this.router.navigate(['disciplines']);
            return false;
          }
          return true;
        }),
      )
      .subscribe({
        next: () => {
          this.setPendoData();
          this.setToursData();
        },
        error: () => {
          this.showAlert = true;
          this.alertType = AlertType.Danger;
        },
      });
  }

  getPersonXid(): Observable<string> {
    return of(this.tokenService.getPersonXid());
  }

  getTouristData(): Observable<TouristResponseData> {
    return this.apiService.getTouristInfo(this.personXid);
  }

  getToursData(
    disciplineUid: string,
    personXid: string,
  ): Observable<ToursResponseData> {
    return this.apiService.getToursByDisciplineUid(disciplineUid, personXid);
  }

  updateSelectedDisciplineData(
    touristDiscipline: TouristDisciplineResponseData,
  ) {
    this.selectedDisciplineId = touristDiscipline.discipline.id;
    this.selectedDisciplineUid = touristDiscipline.discipline.uid;
    this.selectedDisciplineName = touristDiscipline.discipline.name;
    this.selectedCourseId = touristDiscipline.courseId;
    this.selectedSectionId = touristDiscipline.sectionId;
  }

  setSelectedDisciplineData(): void {
    let lastDisciplineSelectedTime: string;
    const touristDisciplines = this.touristResponseData.data.touristDiscipline;

    if (touristDisciplines.length > 0) {
      this.updateSelectedDisciplineData(touristDisciplines[0]);
      if (touristDisciplines[0].updatedOn) {
        lastDisciplineSelectedTime = touristDisciplines[0].updatedOn;
      } else {
        lastDisciplineSelectedTime = touristDisciplines[0].createdOn;
      }

      touristDisciplines.forEach(
        (touristDiscipline: TouristDisciplineResponseData) => {
          // logic to determine last selected discipline data
          if (
            touristDiscipline.updatedOn &&
            touristDiscipline.updatedOn > lastDisciplineSelectedTime
          ) {
            this.updateSelectedDisciplineData(touristDiscipline);
            lastDisciplineSelectedTime = touristDiscipline.updatedOn;
          } else if (touristDiscipline.createdOn > lastDisciplineSelectedTime) {
            this.updateSelectedDisciplineData(touristDiscipline);
            lastDisciplineSelectedTime = touristDiscipline.createdOn;
          }

          // logic to set disciplines data in dropdown options
          this.disciplineDropdownOptions.push({
            value: touristDiscipline.discipline.uid,
            viewValue: touristDiscipline.discipline.name,
            id: 'dropdown-item-discipline-' + touristDiscipline.discipline.id,
          });
        },
      );
    }

    // logic to set selected discipline data in dropdown option
    this.selectedDisciplineDropdownOption = {
      value: this.selectedDisciplineUid,
      viewValue: this.selectedDisciplineName,
    };

    // logic to set dropdown id for selected discipline id
    this.dropdownId =
      'dropdown-selected-discipline-' + this.selectedDisciplineId;
  }

  setPendoData(): void {
    this.pendoService.initialize({ personXid: this.personXid });
    this.toursData.forEach((tour) => {
      if (tour.touristTourSelected) {
        this.pendoService.identify({
          personXid: this.personXid,
          tourUid: tour.uid,
        });
      }
    });
  }

  sortToursByNameAlphabetically(tours: TourData[]): TourData[] {
    return tours.sort((tour1: TourData, tour2: TourData) =>
      tour1.name.localeCompare(tour2.name),
    );
  }

  setInProgressTours(toursResponseData: ToursResponseData): void {
    this.inProgressToursData = toursResponseData.data.filter(
      (tour: TourData) => {
        if (tour.touristTourStatus === TouristTourStatus.IN_PROGRESS) {
          tour.touristTourStatusFrontEnd = 'In Progress';
        }
        return tour.touristTourStatus === TouristTourStatus.IN_PROGRESS;
      },
    );
    this.inProgressToursData = this.sortToursByNameAlphabetically(
      this.inProgressToursData,
    );
    this.toursData = [...this.toursData, ...this.inProgressToursData];
  }

  setRecommendedTours(toursResponseData: ToursResponseData): void {
    this.recommendedToursData = toursResponseData.data.filter(
      (tour: TourData) => {
        return (
          tour.touristTourStatus !== TouristTourStatus.IN_PROGRESS &&
          tour.touristTourStatus !== TouristTourStatus.COMPLETED &&
          tour.touristTourRecommended === true
        );
      },
    );
    this.recommendedToursData = this.sortToursByNameAlphabetically(
      this.recommendedToursData,
    );
    this.toursData = [...this.toursData, ...this.recommendedToursData];
  }

  setOtherTours(toursResponseData: ToursResponseData): void {
    this.otherToursData = toursResponseData.data.filter((tour: TourData) => {
      if (tour.touristTourStatus === TouristTourStatus.TO_DO) {
        tour.touristTourStatusFrontEnd = 'Todo';
      }
      return (
        tour.touristTourStatus !== TouristTourStatus.IN_PROGRESS &&
        tour.touristTourStatus !== TouristTourStatus.COMPLETED &&
        tour.touristTourRecommended !== true
      );
    });
    this.otherToursData = this.sortToursByNameAlphabetically(
      this.otherToursData,
    );
    this.toursData = [...this.toursData, ...this.otherToursData];
  }

  setCompletedTours(toursResponseData: ToursResponseData): void {
    this.completedToursData = toursResponseData.data.filter(
      (tour: TourData) => {
        if (tour.touristTourStatus === TouristTourStatus.COMPLETED) {
          tour.touristTourStatusFrontEnd = 'Completed';
        }
        return tour.touristTourStatus === TouristTourStatus.COMPLETED;
      },
    );
    this.completedToursData = this.sortToursByNameAlphabetically(
      this.completedToursData,
    );
    this.toursData = [...this.toursData, ...this.completedToursData];
  }

  setImagesOfTours(): void {
    const currentLanguage = this.translateService.currentLang || 'en-us';

    this.toursData = this.toursData.map((tour: TourData) => {
      if (tour.touristTourSelected) {
        this.selectedTourUids.push(tour.uid);
      }

      tour.imageSource = `../../../assets/images/tours/${tour.uid}-${currentLanguage}.png`;
      return tour;
    });
  }

  toggleContinueButton(selectedTourUids: string[]): void {
    if (selectedTourUids.length > 0) {
      this.isToursSelected = true;
    } else {
      this.isToursSelected = false;
    }
  }

  setToursData(): void {
    this.inProgressToursData = [];
    this.recommendedToursData = [];
    this.otherToursData = [];
    this.completedToursData = [];
    this.toursData = [];

    this.getToursData(this.selectedDisciplineUid, this.personXid).subscribe(
      (toursResponseData: ToursResponseData) => {
        this.setInProgressTours(toursResponseData);
        this.setRecommendedTours(toursResponseData);
        this.setOtherTours(toursResponseData);
        this.setCompletedTours(toursResponseData);
        this.setImagesOfTours();
        this.toggleContinueButton(this.selectedTourUids);
      },
    );
  }

  selectTour(tour: TourData): void {
    const verifyTourStatus = this.checkTourStatus(tour.uid);
    if (verifyTourStatus === TourStatus.NOT_READY) {
      this.router.navigate(['tour-not-ready']);
    } else {
      this.addTourToTourist(this.personXid, tour.uid);
    }
  }

  checkTourStatus(tourUid: string) {
    let getTourStatus: TourStatus | undefined;
    this.toursData.forEach((tour: TourData) => {
      if (tour.uid === tourUid) {
        getTourStatus = tour.tourStatus;
      }
    });
    return getTourStatus;
  }

  addTourToTourist(personXid: string, tourUid: string): void {
    const reqBody = {
      tourUid,
    };
    this.apiService.addTourToTourist(reqBody, personXid).subscribe({
      next: (res: AddTourToTouristResponseData) => {
        if (res.metadata.code === 200 || res.metadata.code === 304) {
          this.pendoService.identify({
            personXid: this.personXid,
            tourUid,
          });
          this.launchConnectTour();
        }
      },
      error: (e) => {
        console.error(
          `Tour encountered a problem while registering In-Progress status - ${e.message}`,
        );
      },
    });
  }

  launchConnectTour() {
    if (this.selectedCourseId && this.selectedSectionId) {
      this.window.location.href = `${environment.connectUrl}/instructor/course/${this.selectedCourseId}/section/${this.selectedSectionId}`;
    }
  }

  onSectionDropdownChange(selectedOption: DropdownOption): void {
    if (selectedOption.value !== this.selectedDisciplineUid) {
      this.selectedDisciplineUid = selectedOption.value.toString();
      this.setToursData();
    }
  }

  onHighlightMainContent() {
    this.showHighlightOnMain = true;
  }
}
