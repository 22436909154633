<div ngbDropdown class="d-inline-block">
  <button
    ngbDropdownToggle
    type="button"
    class="btn btn-outline-primary w-100 d-flex justify-content-between align-items-center dropdown-button"
    id="{{ dropdownId }}"
    container="body"
    attr.aria-label="{{ selectedOption.viewValue }}"
  >
    <span class="text-truncate">
      {{ selectedOption.viewValue }}
    </span>
    <i class="bi bi-chevron-down"></i>
  </button>
  <div
    ngbDropdownMenu
    [attr.aria-labelledby]="dropdownId"
    role="menu"
    class="w-100"
  >
    <button
      ngbDropdownItem
      type="button"
      role="menuitem"
      class="select-discipline-dropdown"
      *ngFor="let option of options"
      [ngClass]="[
        container === 'body' ? 'd-block text-wrap' : 'd-inline-block text-wrap'
      ]"
      (click)="selectOption(option)"
      id="{{ option.id }}"
    >
      {{ option.viewValue }}
    </button>
  </div>
</div>
