import { createReducer, on } from '@ngrx/store';

import { addItem } from '../actions/example.action';
import { initialState } from '../state/example.state';

export const exampleReducer = createReducer(
  initialState,
  on(addItem, (state, { item }) => {
    return { ...state, items: [...state.items, item] };
  })
);
