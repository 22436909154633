<!-- Button trigger modal -->
<button
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#congratsModal"
  id="launchCongratsModalButton"
  hidden="true"
>
  Launch Congrats Modal Button
</button>

<!-- Modal -->
<div
  class="modal fade"
  id="congratsModal"
  data-bs-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-modal="true"
  aria-labelledby="tour-end-page-title"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="position-relative">
        <button
          class="btn-close congrats-modal-close-button"
          data-bs-dismiss="modal"
          id="congrats-modal-close-button"
          aria-label="Close Congrats modal"
          (click)="routeToTourPage()"
        >
          <span class="d-none" aria-hidden="true">hidden text</span>
        </button>
        <picture>
          <source
            srcset="assets/images/tour-end/congrats.png"
            media="(max-width: 600px)"
          />
          <img
            class="mx-auto congrats-image"
            src="{{ imageSource }}"
            alt="Congrats"
            data-automation-id="congrats-image"
          />
        </picture>
      </div>
      <div class="modal-body">
        <div class="m-lg-5 m-md-3 m-1">
          <h1
            class="mb-3 fs-1 fw-bold"
            data-automation-id="tour-end-page-title"
            id="tour-end-page-title"
            role="heading"
            aria-level="1"
          >
            {{ 'SHOWROOM.TOUR_END.TITLE' | translate }}
          </h1>
          <h2
            class="fs-3 fw-normal"
            data-automation-id="tour-end-page-sub-title"
            id="tour-end-page-sub-title"
            role="heading"
            aria-level="2"
          >
            <span>{{ 'SHOWROOM.TOUR_END.SUB_TITLE_START' | translate }}</span>
            <span>{{ tourName }} </span>
            <span>{{ 'SHOWROOM.TOUR_END.SUB_TITLE_END' | translate }}</span>
          </h2>
        </div>
      </div>
      <div class="modal-footer p-3">
        <div class="m-lg-5 m-md-3 m-1">
          <p
            class="fs-4"
            data-automation-id="tour-end-page-description"
            id="tour-end-page-description"
            hidden="true"
          >
            {{ 'SHOWROOM.TOUR_END.DESCRIPTION' | translate }}
          </p>
          <p
            class="my-4 fs-6 tour-end-page-bottom-description"
            data-automation-id="tour-end-page-bottom-description"
            id="tour-end-page-bottom-description"
            hidden="true"
          >
            {{ 'SHOWROOM.TOUR_END.BOTTOM_DESCRIPTION' | translate }}
          </p>
          <button
            type="button"
            class="tour-end-button tbyb-btn-dark fs-6 mt-2 me-3 px-3 create-an-instructor-account-button"
            id="congrats-modal-create-an-instructor-account-button"
            hidden="true"
          >
            {{
              'SHOWROOM.TOUR_END.CREATE_AN_INSTRUCTOR_ACCOUNT_BUTTON'
                | translate
            }}
          </button>
          <button
            type="button"
            class="tour-end-button tbyb-btn-light fs-6 mt-2 me-3 px-3 contact-a-rep-button"
            id="congrats-modal-contact-a-rep-button"
            hidden="true"
          >
            {{ 'SHOWROOM.TOUR_END.CONTACT_A_REP_BUTTON' | translate }}
          </button>
          <button
            type="button"
            class="tour-end-button tbyb-btn-light fs-6 mt-2 px-3 back-to-tour-menu-button"
            id="congrats-modal-back-to-tour-menu-button"
            data-bs-dismiss="modal"
            (click)="routeToTourPage()"
          >
            {{ 'SHOWROOM.TOUR_END.BACK_TO_TOUR_MENU_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
