<div class="d-flex flex-column min-vh-100">
  <div
    class="flex-grow-1 flex-shrink-0 mt-4 container"
    data-automation-id="disciplines-container"
  >
    <tbyb-header
      (highlightMainContent)="onHighlightMainContent()"
    ></tbyb-header>
    <main
      role="main"
      id="main-content"
      tabindex="-1"
      [ngClass]="{ 'main-container-highlight': showHighlightOnMain }"
    >
      <div class="mt-4">
        <h1
          class="mb-3 fs-1 fw-bold"
          data-automation-id="discipline-page-title"
          id="discipline-page-title"
          role="heading"
          aria-level="1"
        >
          {{ 'SHOWROOM.DISCIPLINES.TITLE' | translate }}
        </h1>
        <h2
          class="mb-2 fs-2 fw-normal"
          data-automation-id="discipline-page-sub-title"
          id="discipline-page-sub-title"
          role="heading"
          aria-level="2"
        >
          {{ 'SHOWROOM.DISCIPLINES.SUB_TITLE' | translate }}
        </h2>
        <h3
          class="fs-4 fw-normal"
          data-automation-id="discipline-page-description"
          id="discipline-page-description"
          role="heading"
          aria-level="3"
        >
          {{ 'SHOWROOM.DISCIPLINES.DESCRIPTION' | translate }}
        </h3>
      </div>
      <div class="col-lg-12">
        <ngb-alert
          *ngIf="showAlert"
          class="mt-3"
          data-automation-id="discipline-page-fetch-error"
          id="discipline-page-fetch-error"
          [type]="alertType"
          [dismissible]="false"
        >
          {{ 'SHOWROOM.DISCIPLINES.FETCH_ERROR' | translate }}
        </ngb-alert>
        <div *ngIf="!showAlert" class="row my-4">
          <ng-container *ngFor="let disciplineData of disciplinesData">
            <div
              class="col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-3 select-discipline select-discipline-card card-container"
              data-automation-id="discipline-card-container"
              id="{{ 'discipline-card-container-' + disciplineData.id }}"
              tabindex="-1"
              (click)="selectDiscipline(disciplineData)"
              (keypress)="selectDiscipline(disciplineData)"
              role="group"
            >
              <div
                class="card shadow"
                data-automation-id="discipline-card"
                id="{{ 'discipline-card-' + disciplineData.id }}"
                [ngClass]="{ 'card-selected': disciplineData.isSelected }"
              >
                <div
                  class="selected-check-icon"
                  *ngIf="disciplineData.isSelected"
                >
                  <i class="bi bi-check-circle-fill"></i>
                </div>
                <img
                  [src]="disciplineData.imageSource"
                  alt="{{ disciplineData.name }}"
                  class="discipline-image mx-auto"
                  data-automation-id="discipline-card-image"
                  id="{{ 'discipline-card-image-' + disciplineData.id }}"
                />
                <div
                  class="card-body d-flex flex-column justify-content-between"
                >
                  <h4
                    class="fs-3 card-title"
                    data-automation-id="discipline-card-title"
                    id="{{ 'discipline-card-title-' + disciplineData.id }}"
                    role="heading"
                    aria-level="4"
                  >
                    {{ disciplineData.name }}
                  </h4>
                  <p
                    data-automation-id="discipline-card-description"
                    class="fs-5 pt-3"
                    id="{{
                      'discipline-card-description-' + disciplineData.id
                    }}"
                  >
                    {{ disciplineData.description }}
                  </p>
                  <div class="card-text mb-4">
                    <button
                      class="tbyb-btn-dark align-self-end select-discipline select-discipline-button mt-3 fs-6"
                      data-automation-id="discipline-card-select-button"
                      id="{{ 'select-discipline-' + disciplineData.id }}"
                      (click)="selectDiscipline(disciplineData)"
                    >
                      {{
                        (disciplineData.isSelected
                          ? 'SHOWROOM.DISCIPLINES.SELECTED_BUTTON'
                          : 'SHOWROOM.DISCIPLINES.SELECT_BUTTON'
                        ) | translate
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!showAlert">
        <div class="mt-3 mb-4">
          <div *ngIf="isErrorMessageVisible">
            <i
              class="bi bi-x-circle error-message-icon"
              data-automation-id="select-discipline-error-message-icon"
            ></i>
            <span
              class="my-4 error-message"
              data-automation-id="select-discipline-error-message"
              id="select-discipline-error-message"
              aria-live="assertive"
              role="alert"
            >
              {{ 'SHOWROOM.DISCIPLINES.ERROR_MESSAGE' | translate }}</span
            >
          </div>
          <button
            class="tbyb-btn-dark fs-5 continue-button mt-3"
            data-automation-id="discipline-page-continue-button"
            id="discipline-page-continue-button"
            (click)="continue()"
            aria-label="Continue to Goals page button"
            aria-describedby="select-discipline-error-message"
          >
            {{ 'SHOWROOM.DISCIPLINES.CONTINUE_BUTTON' | translate }}
          </button>
        </div>
        <div class="my-5 p-4 border rounded">
          <h5
            class="mb-3 fs-2 fw-bold"
            id="discipline-page-already-started-a-tour-title"
            role="heading"
            aria-level="5"
            data-automation-id="discipline-page-already-started-a-tour-title"
          >
            {{
              'SHOWROOM.DISCIPLINES.ALREADY_STARTED_A_TOUR_TITLE' | translate
            }}
          </h5>
          <h6
            class="mb-2 fs-4 fw-normal"
            id="discipline-page-already-started-a-tour-message"
            role="heading"
            aria-level="6"
            data-automation-id="discipline-page-already-started-a-tour-message"
          >
            {{
              'SHOWROOM.DISCIPLINES.ALREADY_STARTED_A_TOUR_MESSAGE' | translate
            }}
          </h6>
          <button
            type="button"
            class="tbyb-btn-light fs-5 mt-3 resume-connect-preview-button"
            id="discipline-page-resume-connect-preview-button"
            data-automation-id="discipline-page-resume-connect-preview-button"
            (click)="resumeConnectPreview()"
          >
            {{
              'SHOWROOM.DISCIPLINES.RESUME_CONNECT_PREVIEW_BUTTON' | translate
            }}
          </button>
        </div>
      </div>
    </main>
  </div>
  <tbyb-footer></tbyb-footer>
</div>
