import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

import { environment } from '../../../environments/environment';
// Service imports
import { ApiService } from '../../services/api.service';
import { TokenService } from '../../services/token.service';
import { PendoService } from '../../services/pendo.service';
// Enum imports
import { AlertType } from '../../enum/alert.enum';
// Model imports
import { DisciplineOnlyData } from '../../models/discipline.model';
import { DisciplinesOnlyResponseData } from '../../models/response/discipline.response.model';

@Component({
  selector: 'tbyb-disciplines',
  templateUrl: './disciplines.component.html',
  styleUrls: ['./disciplines.component.scss'],
})
export class DisciplinesComponent implements OnInit {
  constructor(
    private router: Router,
    private apiService: ApiService,
    private pendoService: PendoService,
    private tokenService: TokenService,
  ) {}

  window = window;
  disciplinesData: DisciplineOnlyData[] = [];
  isErrorMessageVisible = false;
  selectedDisciplineUid = '';
  showAlert = false;
  alertType: AlertType;
  showHighlightOnMain = false;
  personXid = '';

  ngOnInit(): void {
    this.personXid = this.tokenService.getPersonXid();
    this.setPendoData();
    this.getSelectedDisciplineUidObservable()
      .pipe(
        switchMap((selectedDisciplineUid: string) => {
          this.selectedDisciplineUid = selectedDisciplineUid;
          return this.getDisciplineData();
        }),
      )
      .subscribe({
        next: (disciplinesResponseData: DisciplinesOnlyResponseData) => {
          this.setDisciplineData(disciplinesResponseData);
        },
        error: () => {
          this.showAlert = true;
          this.alertType = AlertType.Danger;
        },
      });
  }

  getSelectedDisciplineUidObservable(): Observable<string> {
    return of(this.getSelectedDisciplineUid());
  }

  getSelectedDisciplineUid(): string {
    return sessionStorage.getItem('selectedDisciplineUid') || '';
  }

  getDisciplineData(): Observable<DisciplinesOnlyResponseData> {
    return this.apiService.getAllDisciplines();
  }

  setDisciplineData(
    disciplinesResponseData: DisciplinesOnlyResponseData,
  ): void {
    this.disciplinesData = disciplinesResponseData?.data?.map(
      (discipline: DisciplineOnlyData) => {
        discipline.imageSource = `../../../assets/images/disciplines/${discipline.uid}.svg`;
        discipline.isSelected = false;
        if (discipline.uid === this.selectedDisciplineUid) {
          discipline.isSelected = true;
        }
        return discipline;
      },
    );
  }

  setSelectedDisciplineData(): void {
    this.disciplinesData = this.disciplinesData.map(
      (discipline: DisciplineOnlyData) => {
        discipline.isSelected = false;
        if (discipline.uid === this.selectedDisciplineUid) {
          discipline.isSelected = true;
        }
        return discipline;
      },
    );
  }

  selectDiscipline(disciplineData: DisciplineOnlyData): void {
    this.isErrorMessageVisible = false;
    sessionStorage.setItem('selectedDisciplineUid', disciplineData.uid);
    this.selectedDisciplineUid = disciplineData.uid;
    this.setSelectedDisciplineData();
    sessionStorage.removeItem('selectedGoalsUids');
  }

  continue(): void {
    if (this.selectedDisciplineUid) {
      this.isErrorMessageVisible = false;
      this.router.navigate(['goals']);
    } else {
      this.isErrorMessageVisible = true;
    }
  }

  resumeConnectPreview() {
    this.window.location.href = `${environment.heclrRedirectUrl}`;
  }

  onHighlightMainContent() {
    this.showHighlightOnMain = true;
  }

  setPendoData() {
    if (this.personXid) {
      this.pendoService.initialize({ personXid: this.personXid });
    }
  }
}
