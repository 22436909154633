<div class="d-flex flex-column min-vh-100">
  <div
    class="flex-grow-1 flex-shrink-0 m-5"
    data-automation-id="tour-not-ready-container"
  >
    <tbyb-header></tbyb-header>
    <main
      role="main"
      id="main-content"
      tabindex="-1"
      [ngClass]="{ 'main-container-highlight': showHighlightOnMain }"
    >
      <h1
        class="my-3 fs-1 fw-bold"
        data-automation-id="tour-not-ready-title"
        role="heading"
        aria-level="1"
      >
        {{ 'SHOWROOM.TOUR_NOT_READY.TITLE' | translate }}
      </h1>
      <h2
        class="mb-2 fs-4 fw-normal"
        data-automation-id="tour-not-ready-message"
        role="heading"
        aria-level="2"
      >
        {{ 'SHOWROOM.TOUR_NOT_READY.MESSAGE' | translate }}
        <a
          class="text-decoration-none"
          href="javascript:void(0);"
          tabindex="0"
          data-automation-id="tour-center-page-link"
          id="tour-center-page-link"
          (click)="goToToursPage()"
          >{{ 'SHOWROOM.TOUR_NOT_READY.TOUR_CENTER' | translate }}</a
        >
      </h2>
    </main>
  </div>
  <tbyb-footer></tbyb-footer>
</div>
