import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { CONTACT_LINK } from '../../constants/links.constant';

@Component({
  selector: 'tbyb-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.scss'],
})
export class SystemErrorComponent {
  constructor(private router: Router) {}

  contactLink = CONTACT_LINK;
  showHighlightOnMain = false;

  goToConnectPreview() {
    this.router.navigate(['']);
  }

  onHighlightMainContent() {
    this.showHighlightOnMain = true;
  }
}
