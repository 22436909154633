<div class="d-flex flex-column min-vh-100">
  <div class="flex-grow-1 flex-shrink-0" data-automation-id="tours-container">
    <div class="container mt-4">
      <tbyb-header
        [pageName]="pageName"
        (highlightMainContent)="onHighlightMainContent()"
      ></tbyb-header>
      <main
        role="main"
        id="main-content"
        tabindex="-1"
        [ngClass]="{ 'main-container-highlight': showHighlightOnMain }"
      >
        <h1
          class="my-3 fs-3 fw-normal"
          data-automation-id="tours-page-title"
          id="tours-page-title"
          role="heading"
          aria-level="1"
        >
          {{ 'SHOWROOM.TOURS.TITLE' | translate }}
        </h1>
        <h2
          class="mb-2 fs-1 fw-bold"
          data-automation-id="tours-page-sub-title"
          id="tours-page-sub-title"
          role="heading"
          aria-level="2"
        >
          {{ 'SHOWROOM.TOURS.SUB_TITLE' | translate }}
        </h2>
        <div class="mt-3 pt-4 tours-container">
          <div class="ps-2 d-md-flex justify-content-between">
            <h3
              class="mb-2 fs-3"
              data-automation-id="tours-page-description"
              id="tours-page-description"
            >
              {{ 'SHOWROOM.TOURS.DESCRIPTION' | translate }}
            </h3>
            <div
              *ngIf="!showAlert && disciplineDropdownOptions.length > 1"
              class="d-flex align-items-center"
            >
              <div
                class="me-3 fs-4 fw-bold"
                data-automation-id="tours-page-discipline-label"
                id="tours-page-discipline-label"
              >
                {{ 'SHOWROOM.TOURS.DISCIPLINE' | translate }}
              </div>
              <tbyb-dropdown
                class="discipline-dropdown"
                [options]="disciplineDropdownOptions"
                [selectedOption]="selectedDisciplineDropdownOption"
                [dropdownId]="dropdownId"
                [container]="'target'"
                (selectionChange)="onSectionDropdownChange($event)"
                data-automation-id="tours-page-discipline-dropdown"
                id="tours-page-discipline-dropdown"
              ></tbyb-dropdown>
            </div>
          </div>
          <div class="col-lg-12">
            <ngb-alert
              *ngIf="showAlert"
              class="mt-3"
              data-automation-id="tours-page-fetch-error"
              id="tours-page-fetch-error"
              [type]="alertType"
              [dismissible]="false"
            >
              {{ 'SHOWROOM.TOURS.FETCH_ERROR' | translate }}
            </ngb-alert>
            <div class="row gx-5">
              <ng-container *ngFor="let tourData of toursData">
                <div
                  class="col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-5 pb-5 card-container"
                  role="group"
                >
                  <div class="card tour-card">
                    <div class="position-relative">
                      <img
                        [src]="tourData.imageSource"
                        alt="{{ tourData.name }}"
                        class="card-img-top tour-image"
                      />
                      <div class="position-absolute tour-tag">
                        <span
                          *ngIf="tourData.touristTourRecommended"
                          class="px-2 fs-6 recommended-tour"
                          >Recommended</span
                        >
                        <span
                          *ngIf="tourData.touristTourStatusFrontEnd"
                          class="px-2 tour-state"
                          [ngClass]="{
                          'todo': tourData?.touristTourStatusFrontEnd === 'Todo',
                          'in-progress': tourData?.touristTourStatusFrontEnd === 'In Progress',
                          'completed': tourData?.touristTourStatusFrontEnd === 'Completed',
                        }"
                          >{{ tourData?.touristTourStatusFrontEnd }}</span
                        >
                      </div>
                      <i
                        *ngIf="
                          tourData.touristTourStatusFrontEnd === 'In Progress'
                        "
                        class="bi bi-play-circle-fill position-absolute in-progress-icon"
                      ></i>
                      <i
                        *ngIf="
                          tourData.touristTourStatusFrontEnd === 'Completed'
                        "
                        class="bi bi-arrow-repeat position-absolute completed-icon"
                      ></i>
                    </div>
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                    >
                      <h4
                        class="card-title fs-3 fw-bold mt-2"
                        data-automation-id="tours-card-title"
                        id="{{ 'tours-card-title-' + tourData.id }}"
                      >
                        {{ tourData?.name }}
                      </h4>
                      <p
                        class="fs-6"
                        data-automation-id="tours-card-description"
                        id="{{ 'tours-card-description-' + tourData.id }}"
                      >
                        {{ tourData?.description }}
                      </p>
                      <p
                        class="mt-1 mb-3 p-2 fs-6"
                        data-automation-id="tours-card-goal-name"
                        id="{{ 'tours-card-goal-name-' + tourData.id }}"
                        [ngClass]="{
                          'fw-bold rounded goal-name':
                            tourData.type !== tourType.GENERIC
                        }"
                      >
                        {{
                          tourData.type !== tourType.GENERIC
                            ? tourData?.goalName
                            : ''
                        }}
                      </p>
                      <hr class="separator" />
                      <span
                        class="bi bi-clock"
                        role="img"
                        aria-label="Tour duration"
                        data-automation-id="tours-card-tour-duration"
                        id="{{ 'tours-card-tour-duration-' + tourData.id }}"
                        >{{ tourData?.duration }}</span
                      >
                      <hr class="separator" />
                      <button
                        class="align-self-end fs-6 w-100 tbyb-btn-dark default-tour-button"
                        (click)="selectTour(tourData)"
                        data-automation-id="tours-card-start-tour-button"
                        id="{{ 'start-tour-' + tourData.id }}"
                      >
                        {{
                          (tourData?.touristTourStatusFrontEnd === 'In Progress'
                            ? 'SHOWROOM.TOURS.RESUME_TOUR_BUTTON'
                            : tourData?.touristTourStatusFrontEnd ===
                              'Completed'
                            ? 'SHOWROOM.TOURS.RETAKE_TOUR_BUTTON'
                            : 'SHOWROOM.TOURS.START_TOUR_BUTTON'
                          ) | translate
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <tbyb-footer></tbyb-footer>
</div>
