import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilitiesService {
    checkForMissingParam(params: { name: string, value: number | string | string[] | null }[]) {
        const err: Error[] = [];

        params.forEach((param: { name: string, value: number | string | string[] | null }) => {
            if (!param.value || param.value.toString().length <= 0) {
                err.push(new Error(`Missing Parameter: ${param.name}`));
            }
        });
        return err;
    }
}
