import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LanguageOption } from './enum/language.enum';
import { TokenRefreshService } from './services/token-refresh.service';

@Component({
  selector: 'tbyb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private destroy$ = new Subject<boolean>();
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private tokenRefreshService: TokenRefreshService,
  ) {}

  ngOnInit() {
    this.setLanguage();
    this.setPageTitle();
    this.refreshToken();
  }

  setLanguage(): void {
    const language = LanguageOption.English;
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
  }

  setPageTitle(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((titleObj) => {
        this.translateService
          .stream(`NAV.${titleObj['pageTitleTranslationKey']}`)
          .pipe(takeUntil(this.destroy$))
          .subscribe((translatedTitle) => {
            this.titleService.setTitle(translatedTitle);
          });
      });
  }

  refreshToken(): void {
    this.tokenRefreshService.refreshToken();
  }
}
