import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Md5 } from 'ts-md5';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PasscodeGuard implements CanActivate {
  window = window;
  hashLocalKey = 'tour';
  hashLocalValue = Md5.hashStr('tourConnectDemo');

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (
      localStorage.getItem(this.hashLocalKey)?.includes(this.hashLocalValue)
    ) {
      return true;
    } else if (['non-prod'].includes(environment.envName)) {
      this.router.navigate(['authenticate'], {
        queryParams: { returnUrl: state.url.replace('/', '') },
      });
      return false;
    } else {
      return true;
    }
  }
}
