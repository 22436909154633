import { Component } from '@angular/core';

import {
  PRIVACY_CENTER_LINK,
  SITE_MAP_LINK,
  TERMS_OF_USE_LINK,
} from '../../constants/links.constant';

@Component({
  selector: 'tbyb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  termsOfUseLink = TERMS_OF_USE_LINK;
  privacyCenterLink = PRIVACY_CENTER_LINK;
  siteMapLink = SITE_MAP_LINK;
}
