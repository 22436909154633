import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { ExampleComponent } from './example.component';

@NgModule({
  declarations: [ExampleComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    NgbAlertModule,
  ],
  exports: [ExampleComponent],
})
export class ExampleModule {}
