import { Component, EventEmitter, Input, Output } from '@angular/core';

// Enum imports
import { DropdownOption } from '../../enum/dropdown-option';

@Component({
  selector: 'tbyb-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() container = 'body';
  @Input() options: DropdownOption[] = [];
  @Input() selectedOption: DropdownOption;
  @Input() dropdownId: string;
  @Output() selectionChange = new EventEmitter();

  selectOption(selectedOption: DropdownOption) {
    this.selectedOption = selectedOption;
    this.updateDropdown();
  }

  updateDropdown() {
    this.selectionChange.emit(this.selectedOption);
  }
}
