export enum GoalType {
  GENERIC = 'GENERIC',
  NON_GENERIC = 'NON_GENERIC',
  UNCATEGORIZED = 'UNCATEGORIZED',
}

export enum GoalStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}
