import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tbyb-tour-not-ready',
  templateUrl: './tour-not-ready.component.html',
})
export class TourNotReadyComponent {
  constructor(public router: Router) {}

  showHighlightOnMain = false;

  goToToursPage() {
    this.router.navigate(['tours']);
  }

  onHighlightMainContent() {
    this.showHighlightOnMain = true;
  }
}
