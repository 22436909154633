<div class="d-flex flex-column min-vh-100">
  <div
    class="flex-grow-1 flex-shrink-0 m-5"
    data-automation-id="page-not-found-container"
  >
    <tbyb-header></tbyb-header>
    <main
      role="main"
      id="main-content"
      tabindex="-1"
      class="d-md-flex"
      [ngClass]="{ 'main-container-highlight': showHighlightOnMain }"
    >
      <div class="w-75 me-5 mb-5">
        <h1
          class="my-3 fs-1 fw-bold"
          data-automation-id="page-not-found-title"
          role="heading"
          aria-level="1"
        >
          {{ 'SHOWROOM.PAGE_NOT_FOUND.TITLE' | translate }}
        </h1>
        <h2
          class="mb-2 fs-4 fw-normal"
          data-automation-id="page-not-found-message"
          role="heading"
          aria-level="2"
        >
          {{ 'SHOWROOM.PAGE_NOT_FOUND.MESSAGE_START' | translate }}
          <a
            class="text-decoration-none"
            href="javascript:void(0);"
            tabindex="0"
            data-automation-id="page-not-found-somewhere-more-useful-link"
            (click)="onSomewhereMoreUsefulClick()"
            >{{ 'SHOWROOM.PAGE_NOT_FOUND.MESSAGE_END' | translate }}</a
          >
        </h2>
      </div>
      <div class="w-25 me-5">
        <img
          class="d-inline-block align-top magnifying-glass"
          src="assets/images/magnifying-glass.png"
          alt="Page not found"
          data-automation-id="page-not-found-magnifying-glass"
        />
      </div>
    </main>
  </div>
  <tbyb-footer></tbyb-footer>
</div>
