import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Service imports
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'tbyb-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(private router: Router, private tokenService: TokenService) {}

  isUserLoggedIn = false;
  showHighlightOnMain = false;

  ngOnInit(): void {
    this.isUserLoggedIn = this.tokenService.isUserLoggedIn();
  }

  onSomewhereMoreUsefulClick() {
    if (this.isUserLoggedIn) {
      this.router.navigate(['tours']);
    } else {
      this.router.navigate(['disciplines']);
    }
  }

  onHighlightMainContent() {
    this.showHighlightOnMain = true;
  }
}
