import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Observable, filter, of, switchMap } from 'rxjs';

import { environment } from '../../../environments/environment';
// Service imports
import { ApiService } from '../../services/api.service';
import { TokenService } from '../../services/token.service';
import { PendoService } from '../../services/pendo.service';
// Enum imports
import { AlertType } from '../../enum/alert.enum';
import { GoalType } from '../../enum/goal.enum';
// Model imports
import { GoalResponseData } from '../../models/response/goal.response.model';
import { GoalData } from '../../models/goal.model';

@Component({
  selector: 'tbyb-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss'],
})
export class GoalsComponent implements OnInit {
  constructor(
    private router: Router,
    private apiService: ApiService,
    private pendoService: PendoService,
    private tokenService: TokenService,
    private cookieService: CookieService,
  ) {}

  window = window;
  showHighlightOnMain = false;
  selectedDisciplineUid = '';
  selectedGoalsUids: string[] = [];
  goalsData: GoalData[] = [];
  isErrorMessageVisible = false;
  personXid = '';
  showAlert = false;
  alertType: AlertType;

  ngOnInit(): void {
    const personXid = this.tokenService.getPersonXid();
    this.personXid = personXid ? personXid : '';
    this.setPendoData();
    this.getSelectedDisciplineUidObservable()
      .pipe(
        filter((selectedDisciplineUid: string) => {
          if (!selectedDisciplineUid) {
            this.router.navigate(['disciplines']);
            return false;
          }
          this.selectedDisciplineUid = selectedDisciplineUid;
          return true;
        }),
        switchMap((selectedDisciplineUid: string) =>
          this.getGoalsData(selectedDisciplineUid, this.personXid),
        ),
        switchMap((goalData: GoalResponseData) => {
          this.goalsData = goalData?.data.filter(
            (goalData: GoalData) => goalData.type !== GoalType.UNCATEGORIZED,
          );
          return this.getSelectedGoalsObservable();
        }),
      )
      .subscribe({
        next: (selectedGoalsUids: string[]) => {
          this.selectedGoalsUids = selectedGoalsUids;
          this.setSelectedGoals(this.selectedGoalsUids);
        },
        error: () => {
          this.showAlert = true;
          this.alertType = AlertType.Danger;
        },
      });
  }

  getSelectedDisciplineUidObservable(): Observable<string> {
    return of(this.getSelectedDisciplineUid());
  }

  getSelectedDisciplineUid(): string {
    return sessionStorage.getItem('selectedDisciplineUid') || '';
  }

  getGoalsData(
    selectedDisciplineUid: string,
    personXid?: string,
  ): Observable<GoalResponseData> {
    return this.apiService.getGoalsByDisciplineUid(
      selectedDisciplineUid,
      personXid,
    );
  }

  getSelectedGoalsObservable(): Observable<string[]> {
    return of(this.getSelectedGoals());
  }

  getSelectedGoals(): string[] {
    return sessionStorage.getItem('selectedGoalsUids')?.split(',').flat() || [];
  }

  selectGoal(goalUid: string): void {
    let selectedGoalsUidsLocal = this.getSelectedGoals();
    if (selectedGoalsUidsLocal.indexOf(goalUid) === -1) {
      selectedGoalsUidsLocal.push(goalUid);
    } else {
      selectedGoalsUidsLocal.splice(selectedGoalsUidsLocal.indexOf(goalUid), 1);
    }
    selectedGoalsUidsLocal = selectedGoalsUidsLocal.filter((ele) => ele);
    this.setSelectedGoals(selectedGoalsUidsLocal);
    this.selectedGoalsUids = selectedGoalsUidsLocal;
    sessionStorage.setItem(
      'selectedGoalsUids',
      selectedGoalsUidsLocal.join(','),
    );
    this.validateGoalsSelection();
  }

  goBack(): void {
    this.router.navigate(['disciplines']);
  }

  continue(): void {
    this.validateGoalsSelection();
    if (!this.isErrorMessageVisible) {
      // Below condition is to skip the login process for logged in user.
      if (this.personXid) {
        this.router.navigate(['postLogin']);
      } else {
        this.window.location.href = `${
          environment.heclrRedirectUrl
        }&discipline=${
          this.selectedDisciplineUid
        }&goals=${this.selectedGoalsUids.join(',')}`;
      }
    }
  }

  validateGoalsSelection() {
    if (this.selectedGoalsUids.length) {
      this.isErrorMessageVisible = false;
    } else {
      this.isErrorMessageVisible = true;
    }
  }

  setSelectedGoals(selectedGoalsUids: string[]): void {
    this.goalsData = this.goalsData?.map((goal) => {
      if (selectedGoalsUids.indexOf(goal.uid) !== -1) {
        goal.touristGoalSelected = true;
      } else {
        goal.touristGoalSelected = false;
      }
      return goal;
    });
  }

  onHighlightMainContent() {
    this.showHighlightOnMain = true;
  }

  setPendoData() {
    if (this.personXid) {
      this.pendoService.initialize({ personXid: this.personXid });
    }
  }
}
