import { createAction, props } from '@ngrx/store';

export const addTour = createAction(
  '[Tour] Add Tour',
  props<{ tour: string; tourUrl: string }>(),
);

export const removeTour = createAction(
  '[Tour] Remove Tour',
  props<{ tour: string; tourUrl: string }>(),
);
