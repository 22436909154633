export enum TourType {
  GENERIC = 'GENERIC',
  NON_GENERIC = 'NON_GENERIC',
  UNCATEGORIZED = 'UNCATEGORIZED',
}

export enum TourStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  NOT_READY = 'NOT_READY',
}
