import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Component imports
import { DisciplinesComponent } from './components/disciplines/disciplines.component';
import { GoalsComponent } from './components/goals/goals.component';
import { ToursComponent } from './components/tours/tours.component';
import { TourNotReadyComponent } from './components/tour-not-ready/tour-not-ready.component';
import { TourEndComponent } from './components/tour-end/tour-end.component';
import { PostLoginComponent } from './components/post-login/post-login.component';
// import { ExampleComponent } from './components/example/example.component';
import { PasscodeComponent } from './components/passcode/passcode.component';
import { SystemErrorComponent } from './components/system-error/system-error.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
// Guard imports
import { PasscodeGuard } from './guards/passcode.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    data: { pageTitleTranslationKey: 'DISCIPLINES' },
    component: DisciplinesComponent,
    canActivate: [PasscodeGuard],
  },
  {
    path: 'disciplines',
    data: { pageTitleTranslationKey: 'DISCIPLINES' },
    component: DisciplinesComponent,
    canActivate: [PasscodeGuard],
  },
  {
    path: 'goals',
    data: { pageTitleTranslationKey: 'GOALS' },
    component: GoalsComponent,
    canActivate: [PasscodeGuard],
  },
  {
    path: 'tours',
    data: { pageTitleTranslationKey: 'TOURS' },
    component: ToursComponent,
    canActivate: [PasscodeGuard, AuthGuard],
  },
  {
    path: 'tour-not-ready',
    data: { pageTitleTranslationKey: 'TOUR_NOT_READY' },
    component: TourNotReadyComponent,
    canActivate: [PasscodeGuard, AuthGuard],
  },
  {
    path: 'tourEnd',
    data: { pageTitleTranslationKey: 'TOUR_END' },
    component: TourEndComponent,
    canActivate: [PasscodeGuard, AuthGuard],
  },
  {
    path: 'postLogin',
    data: { pageTitleTranslationKey: 'POST_LOGIN' },
    component: PostLoginComponent,
    canActivate: [PasscodeGuard, AuthGuard],
  },
  // { path: 'example', component: ExampleComponent },
  {
    path: 'authenticate',
    data: { pageTitleTranslationKey: 'AUTHENTICATE' },
    component: PasscodeComponent,
  },
  {
    path: 'system-error',
    data: { pageTitleTranslationKey: 'SYSTEM_ERROR' },
    component: SystemErrorComponent,
    canActivate: [PasscodeGuard],
  },
  {
    path: 'unauthorized',
    data: { pageTitleTranslationKey: 'UNAUTHORIZED' },
    component: UnauthorizedComponent,
    canActivate: [PasscodeGuard],
  },
  {
    path: '**',
    data: { pageTitleTranslationKey: 'PAGE_NOT_FOUND' },
    component: PageNotFoundComponent,
    canActivate: [PasscodeGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
