import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tbyb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() pageName: string;
  @Output() highlightMainContent = new EventEmitter<boolean>();

  userInitials = '';
  showHeaderButtonsForMobile = false;

  ngOnInit(): void {
    this.getUserInitials();
  }

  getUserInitials() {
    // TODO : Get the firstName and lastName from Token
    const firstName = 'susindhiran';
    const lastName = 'jeyaraman';

    this.userInitials =
      firstName.toUpperCase().charAt(0) + lastName.toUpperCase().charAt(0);
  }

  toggleHeadersForMobile() {
    this.showHeaderButtonsForMobile = !this.showHeaderButtonsForMobile;
  }

  onSkipToMainClick() {
    document.querySelector<HTMLElement>('#main-content')?.focus();
    this.highlightMainContent.emit(true);
  }
}
