import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
// Service imports
import { UtilitiesService } from './utilities.service';
import { TokenService } from './token.service';
// Model imports
import {
  TouristUpdateTourStatusRequest,
  AddTourToTouristRequest,
} from '../models/request/tourist.request.model';
import {
  DisciplineResponseData,
  DisciplinesOnlyResponseData,
} from '../models/response/discipline.response.model';
import { GoalResponseData } from '../models/response/goal.response.model';
import { ToursResponseData } from '../models/response/tour.response.model';
import {
  TouristResponseData,
  TouristUpdateTourResponseData,
  AddTourToTouristResponseData,
} from '../models/response/tourist.response.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
    private utilities: UtilitiesService,
    private tokenService: TokenService,
  ) {}

  _retryTimes = 0;
  tokenRefreshTime = 270 * 1000;
  showroomApiUrl = environment.showroomApiUrl;
  connectUrl = environment.connectUrl;

  getHttpHeaders(): HttpHeaders {
    return this.tokenService.getCookie('MH_TOKEN')
      ? new HttpHeaders().set(
          'Authorization',
          `Bearer ${this.tokenService.getCookie('MH_TOKEN')}`,
        )
      : new HttpHeaders();
  }

  getAllDisciplines(): Observable<DisciplinesOnlyResponseData> {
    return this.http
      .get<DisciplinesOnlyResponseData>(`${this.showroomApiUrl}/disciplines`)
      .pipe(
        tap((disciplineData) => {
          return disciplineData;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  getDisciplineByDisciplineUid(
    disciplineUid: number | string,
  ): Observable<DisciplineResponseData> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: disciplineUid, name: 'disciplineUid' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }

    return this.http
      .get<DisciplineResponseData>(
        `${this.showroomApiUrl}/disciplines/${disciplineUid}`,
      )
      .pipe(
        tap((disciplineResponseData: DisciplineResponseData) => {
          return disciplineResponseData;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  getToursByDisciplineUid(
    disciplineUid: string,
    personXid: string,
  ): Observable<ToursResponseData> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: disciplineUid, name: 'disciplineUid' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }

    return this.http
      .get<ToursResponseData>(
        `${this.showroomApiUrl}/disciplines/${disciplineUid}/tours${
          personXid && `?personXid=${personXid}`
        }`,
        { headers: this.getHttpHeaders() },
      )
      .pipe(
        tap((toursResponseData: ToursResponseData) => {
          return toursResponseData;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  getGoalsByDisciplineUid(
    disciplineUid: number | string,
    personXid?: string,
  ): Observable<GoalResponseData> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: disciplineUid, name: 'disciplineUid' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }
    let queryParams = new HttpParams();
    if (personXid) {
      queryParams = queryParams.append('personXid', personXid);
    }

    return this.http
      .get<GoalResponseData>(
        `${this.showroomApiUrl}/disciplines/${disciplineUid}/goals`,
        { params: queryParams },
      )
      .pipe(
        tap((goalsResponseData: GoalResponseData) => {
          return goalsResponseData;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  getGoalsByGoalUids(goalUids: string[]): Observable<GoalResponseData> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: goalUids, name: 'goalUids' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }

    const params = new HttpParams().append('selectedGoalUids', goalUids.join());

    return this.http
      .get<GoalResponseData>(`${this.showroomApiUrl}/goals`, { params })
      .pipe(
        tap((goalsResponseData: GoalResponseData) => {
          return goalsResponseData;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  addTourToTourist(
    reqBody: AddTourToTouristRequest,
    personXid: string,
  ): Observable<AddTourToTouristResponseData> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: reqBody.tourUid, name: 'tourUid' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }
    const reqUrl = `${this.showroomApiUrl}/tourist/${personXid}/tour`;

    return this.http
      .post<AddTourToTouristResponseData>(reqUrl, reqBody, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((touristTourStatusResponseData: AddTourToTouristResponseData) => {
          return touristTourStatusResponseData;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  updateTouristTourStatus(
    body: TouristUpdateTourStatusRequest,
  ): Observable<TouristUpdateTourResponseData> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: body.status, name: 'status' },
      { value: body.tourUid, name: 'tourUid' },
      { value: body.personXid, name: 'personXid' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }
    const reqUrl = `${this.showroomApiUrl}/tourist/${body.personXid}/tour/${body.tourUid}/status`;
    const reqBody = { status: body.status };
    return this.http
      .put<TouristUpdateTourResponseData>(reqUrl, reqBody, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((touristTourStatusResponseData: TouristUpdateTourResponseData) => {
          return touristTourStatusResponseData;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  getTouristInfo(personXid: string): Observable<TouristResponseData> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: personXid, name: 'personXid' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }

    return this.http
      .get<TouristResponseData>(`${this.showroomApiUrl}/tourist/${personXid}`, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((touristResponseData: TouristResponseData) => {
          return touristResponseData;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  addTourist(
    personXid: string,
    disciplineUid: string,
    goalUid: string,
  ): Observable<TouristResponseData> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: personXid, name: 'personXid' },
      { value: disciplineUid, name: 'disciplineUid' },
      { value: goalUid, name: 'goalUid' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }

    const reqUrl = `${this.showroomApiUrl}/tourist/${personXid}`;
    const reqBody = { disciplineUid, goalUid };

    return this.http
      .put<TouristResponseData>(reqUrl, reqBody, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((addTouristData: TouristResponseData) => {
          return addTouristData;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }
  /**
   * Leaving API as-is.
   * discussion of return value from HTML form to url
   * or some other return value.
   */
  launchConnect(
    disciplineId: number,
    courseId: number,
    sectionId: number,
  ): Observable<HttpResponse<string>> {
    const paramErrors = this.utilities.checkForMissingParam([
      { value: disciplineId, name: 'disciplineId' },
      { value: courseId, name: 'courseId' },
      { value: sectionId, name: 'sectionId' },
    ]);

    if (paramErrors.length) {
      return throwError(() => paramErrors);
    }

    const reqUrl = `${this.connectUrl}/caas/tourist/launchConnect`;
    const reqBody = { disciplineId, courseId, sectionId };

    return this.http
      .post(reqUrl, reqBody, {
        withCredentials: true,
        observe: 'response',
        responseType: 'text',
        headers: new HttpHeaders({
          personXid: this.tokenService.getPersonXid(),
        }),
      })
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  set retryTimes(count: number) {
    this._retryTimes = count;
  }

  get retryTimes(): number {
    return this._retryTimes;
  }

  refreshToken() {
    const reqUrl = `${this.showroomApiUrl}/token/refresh`;

    return this.http
      .get(reqUrl, { headers: this.getHttpHeaders(), responseType: 'text' })
      .pipe(
        tap(() => {
          this.retryTimes = 0;
          setTimeout(() => {
            this.refreshToken().subscribe();
          }, this.tokenRefreshTime);
        }),
        catchError((err) => throwError(() => err)),
      );
  }
}
