<footer data-automation-id="footer-container">
  <nav id="footer-nav" aria-label="Main">
    <div class="p-3 footer-links-container">
      <div class="d-flex justify-content-center fw-bold footer-container-links">
        <div class="site-footer" data-automation-id="terms-of-use">
          <a
            class="footer-links"
            href="{{ termsOfUseLink }}"
            target="_blank"
            data-automation-id="terms-of-use-link"
          >
            {{ 'SHOWROOM.FOOTER.TERMS_OF_USE' | translate }}
            <i class="bi bi-box-arrow-up-right ms-2"></i>
          </a>
        </div>
        <div class="site-footer" data-automation-id="privacy-center">
          <a
            class="footer-links"
            href="{{ privacyCenterLink }}"
            target="_blank"
            data-automation-id="privacy-center-link"
          >
            {{ 'SHOWROOM.FOOTER.PRIVACY_CENTER' | translate }}
            <i class="bi bi-box-arrow-up-right ms-2"></i>
          </a>
        </div>
        <div data-automation-id="site-map">
          <a
            class="footer-links"
            href="{{ siteMapLink }}"
            target="_blank"
            data-automation-id="site-map-link"
          >
            {{ 'SHOWROOM.FOOTER.SITE_MAP' | translate }}
            <i class="bi bi-box-arrow-up-right ms-2"></i>
          </a>
        </div>
      </div>
      <div
        class="d-flex justify-content-center mt-2"
        data-automation-id="copyright"
      >
        <i class="bi bi-c-circle me-2"></i>
        {{ 'SHOWROOM.FOOTER.COPYRIGHT' | translate }}
      </div>
    </div>
    <div class="p-3 d-flex justify-content-center footer-cookies-container">
      <div class="me-2" data-automation-id="use-of-cookies">
        {{ 'SHOWROOM.FOOTER.USE_OF_COOKIES' | translate }}
      </div>
      <div class="fw-bold" data-automation-id="review-use-of-cookies">
        <a
          class="footer-links"
          href="{{ privacyCenterLink }}"
          target="_blank"
          data-automation-id="review-use-of-cookies-link"
        >
          {{ 'SHOWROOM.FOOTER.REVIEW_USE_OF_COOKIES' | translate }}
          <i class="bi bi-box-arrow-up-right ms-2"></i>
        </a>
      </div>
    </div>
  </nav>
</footer>
