import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// Service imports
import { ApiService } from '../../services/api.service';
import { PendoService } from '../../services/pendo.service';
import { TokenService } from '../../services/token.service';
// Enum imports
import { TouristTourStatus } from '../../enum/tourist.enum';
// Model imports
import { TouristUpdateTourResponseData } from '../../models/response/tourist.response.model';

@Component({
  selector: 'tbyb-tour-end',
  templateUrl: './tour-end.component.html',
  styleUrls: ['./tour-end.component.scss'],
})
export class TourEndComponent implements OnInit {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private apiService: ApiService,
    private pendoService: PendoService,
    private tokenService: TokenService,
    private route: ActivatedRoute,
  ) {}

  tourName = '';
  personXid = '';
  tourUid = '';
  status = TouristTourStatus.COMPLETED;
  currentLanguage = this.translateService.currentLang || 'en-us';
  imageSource = `assets/images/tour-end/congrats-${this.currentLanguage}.png`;

  ngOnInit(): void {
    this.personXid = this.tokenService.getPersonXid();
    this.personXid &&
      this.pendoService.identify({ personXid: this.personXid, tourUid: '' });
    this.tourUid = this.route.snapshot.queryParamMap.get('tour') || '';
    if (this.tourUid && this.personXid && this.status) {
      this.handleTourEnd(this.personXid, this.tourUid, this.status);
    } else {
      this.routeToTourPage();
    }
  }

  handleTourEnd(personXid: string, tourUid: string, status: TouristTourStatus) {
    const reqBody = {
      personXid,
      tourUid,
      status,
    };
    this.apiService.updateTouristTourStatus(reqBody).subscribe({
      next: (res: TouristUpdateTourResponseData) => {
        res?.data.forEach((touristTour) => {
          if (
            touristTour.tour.uid === tourUid &&
            touristTour.status === status
          ) {
            this.tourName = touristTour.tour.name;
          }
        });

        if (!this.tourName) {
          this.routeToTourPage();
        } else {
          this.launchCongratsModal();
        }
      },
      error: (e) => {
        console.error(
          `Tour encountered a problem while registering completed status - ${e.message}`,
        );
        this.routeToTourPage();
      },
    });
  }

  routeToTourPage() {
    this.router.navigate(['tours']);
  }

  launchCongratsModal() {
    const myButton = document.getElementById('launchCongratsModalButton');
    myButton?.click();
  }
}
