<header>
  <ul
    class="position-absolute start-50 list-unstyled skip-link-list"
    tabindex="-1"
    data-automation-id="skip-links"
  >
    <li>
      <a
        class="rounded-3 p-3 skip-link-item"
        href="javascript:void(0);"
        tabindex="0"
        aria-hidden="false"
        data-automation-id="skip-to-main-content-link"
        id="skip-to-main-content-link"
        (click)="onSkipToMainClick()"
        >{{ 'SHOWROOM.HEADER.SKIP_TO_MAIN_CONTENT' | translate }}
      </a>
    </li>
  </ul>
  <div
    class="d-flex justify-content-between align-items-center"
    data-automation-id="header-container"
  >
    <img
      class="connect-logo d-inline-block align-top"
      src="assets/images/MHHE_Logo.svg"
      alt="McGraw Hill Connect logo"
      data-automation-id="connect-logo"
    />

    <!-- note header buttons are temporarily hidden via css: TCN-1162 -->
    <div class="header-buttons" *ngIf="pageName === 'tours'">
      <button
        type="button"
        class="tbyb-header-btn-light fs-5"
        data-automation-id="create-full-account-button"
        id="header-page-create-full-account-button"
      >
        {{ 'SHOWROOM.HEADER.CREATE_FULL_ACCOUNT_BUTTON' | translate }}
      </button>
      &nbsp;
      <button
        type="button"
        class="tbyb-header-btn-light fs-5"
        data-automation-id="contact-a-rep-button"
        id="header-page-contact-a-rep-button"
      >
        {{ 'SHOWROOM.HEADER.CONTACT_A_REP_BUTTON' | translate }}
      </button>
      &nbsp;
      <button
        type="button"
        class="tbyb-header-btn-dark px-8 py-9 fs-5"
        data-automation-id="user-profile-menu-button"
        id="user-profile-menu-button"
      >
        {{ userInitials }}
      </button>
    </div>

    <div class="header-buttons-mobile-view" *ngIf="pageName === 'tours'">
      <button
        type="button"
        class="header-menu-button"
        aria-label="Header Menu Button"
        (click)="toggleHeadersForMobile()"
      >
        <i class="bi bi-list"></i>
      </button>
    </div>
  </div>
  <div
    class="header-buttons-mobile-view header-options-mobile-view"
    *ngIf="pageName === 'tours' && showHeaderButtonsForMobile"
  >
    <button
      type="button"
      class="tbyb-mobile-header-btn fs-4 mt-3"
      data-automation-id="create-full-account-button"
      id="header-page-create-full-account-button-mobile-view"
    >
      {{ 'SHOWROOM.HEADER.CREATE_FULL_ACCOUNT_BUTTON' | translate }}
    </button>
    <button
      type="button"
      class="tbyb-mobile-header-btn fs-4"
      data-automation-id="contact-a-rep-button"
      id="header-page-contact-a-rep-button-mobile-view"
    >
      {{ 'SHOWROOM.HEADER.CONTACT_A_REP_BUTTON' | translate }}
    </button>
    <button
      type="button"
      class="tbyb-mobile-header-btn fs-4"
      data-automation-id="user-profile-menu-button"
      id="user-profile-menu-button-mobile-view"
    >
      {{ 'SHOWROOM.HEADER.MY_ACCOUNT' | translate }}
    </button>
  </div>
</header>
